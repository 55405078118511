export default {
	namespaced: true,
	state: {
		userInfo: {
			token: ''
		}
	},
	mutations: {},
	actions: {}
}
