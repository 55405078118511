<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>
<script>
export default {
	data() {
		return {}
	},
	created() {},
	mounted() {
		// 页面刷新时 不停留在当前位置 返回页面顶部。该方式主要是为了使得getBoundingClientRect方法获取的距离是正确的初始化数值。
		if (history.scrollRestoration) {
			history.scrollRestoration = 'manual'
		}
	},
	methods: {}
}
</script>

<style lang="less">
html,
body {
	width: 100%;
	// 去除移动端点击元素时产生的阴影效果
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	scroll-behavior: smooth;
}
#app {
	//min-width: 1200px;
	font-size: 16px;
	//font-family:
	//	Source Han Sans CN-Regular,
	//	Source Han Sans CN;
	font-weight: 400;
}
</style>
