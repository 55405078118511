import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 样式
import '@/assets/styles/global.css'
import '@/assets/styles/reset.css'
import '@/assets/styles/less/global/index.js'

// 按需引入 element-ui
import { Collapse, CollapseItem } from 'element-ui'
import ElementLocale from 'element-ui/lib/locale'
// 自定义element组件样式
import '@/assets/styles/element/index.js'
// 国际化
import i18n from '@/lang'
// 全局组件
import '@/components'
// 全局指令
import '@/utils/directives'
import 'components/common/toast/index.js'

Vue.use(Collapse)
Vue.use(CollapseItem)
// 由于element-ui对于Message的架构设计，并无install方法。所以这里不能调用Vue.use()。如果想用this.$message，请使用Vue.prototype.$mesage=Message来手动注册
// Vue.use(Message)

// 引入极验
// import GeeTest from '@cbibank/geetest'
// import geeTestApi from '@/api/geeTest'

// Vue.use(GeeTest, {
// 	appCode: 'Your-App-Code',
// 	authApi: {
// 		getAccess: geeTestApi.getAccess
// 	}
// })
// 极验使用：
// const geeTest = this.$geeTest.create(options)
// geeTest.validate()

if (process.env.VUE_APP_H5_COMPATIBLE === 'pxToRem') {
	import('lib-flexible')
}

ElementLocale.i18n((key, value) => i18n.t(key, value))

Vue.config.productionTip = false

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
