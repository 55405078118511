export default [
	{
		path: '/',
		name: 'Index',
		component: () => import('@/views/layout'),
		meta: {
			loginAuth: true
		},
		redirect: '/',
		// 承载需要layout的公共路由
		children: [
			{
				path: '/',
				name: 'Home',
				meta: {
					title: '首页'
				},
				component: () => import('@/views/home/Index')
			},
			{
				path: '/privacy-protocol',
				name: 'PrivacyProtocol',
				meta: {
					title: '隐私协议',
					hideFooter: true
				},
				component: () => import('@/views/protocol/PrivacyProtocol.vue')
			},
			{
				path: '/service-protocol',
				name: 'ServiceProtocol',
				meta: {
					title: '服务协议',
					hideFooter: true
				},
				component: () => import('@/views/protocol/ServiceProtocol.vue')
			}
		]
	}
]
