<template>
	<div
		class="button"
		:class="[
			{ loading },
			{ disabled: loading || disabled },
			{ circle },
			type,
			size,
			line ? 'line' : ''
		]"
		@click="handleClick">
		<div
			v-if="loading"
			class="is-loading">
			<!--<SvgIcon-->
			<!--	class="loading"-->
			<!--	name="loading"></SvgIcon>-->
		</div>
		<span
			class="container"
			:class="[
				arrowConfig.moving ? 'moving' : '',
				arrowConfig.suspend ? 'suspend' : ''
			]">
			<slot></slot>
			<!--<SvgIcon-->
			<!--	class="arrow"-->
			<!--	name="arrow-right"></SvgIcon>-->
		</span>
	</div>
</template>
<script>
export default {
	props: {
		// blue gray blank
		type: {
			type: String,
			default: 'blue'
		},
		// 默认large 高50px medium 46px small 高36px
		size: {
			type: String,
			default: 'large'
		},
		line: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		circle: {
			type: Boolean,
			default: false
		},
		arrowConfig: {
			type: Object,
			default() {
				return {
					moving: false,
					suspend: false
				}
			}
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {}
	},
	methods: {
		handleClick(e) {
			console.log(1111)
			this.$emit('click')
		}
	}
}
</script>

<style scoped lang="less">
.button {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	user-select: none;
	line-height: 1;
	color: var(--cbi-top-white);
	text-align: center;
	transition: all ease-in-out 0.5s;
	z-index: 1;
	overflow: hidden;
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0.1) 0%,
			rgba(255, 255, 255, 0.2) 100%
		);
		top: 0;
		left: 0;
		border-radius: inherit;
		z-index: -1;
		transition: all 400ms ease;
		transform: translateX(-100%);
	}
	&:hover {
		&::before {
			width: 100%;
			transform: translateX(0);
		}
		.container {
			&.moving {
				.arrow {
					transform: translateX(6px);
				}
			}
			&.suspend {
				.arrow {
					transform: translate((100% + 30px), -50%);
					opacity: 1;
				}
			}
		}
	}
	&.loading {
		.is-loading {
			display: flex;
			justify-content: center;
			.loading {
				animation: rotating 2s linear infinite;
			}
		}
		.container {
			margin-left: 6px;
		}
	}

	&.disabled {
		cursor: not-allowed;
	}
	&.red {
		background-color: var(--cbi-red);
		&.disabled {
			background-color: var(--cbi-red-2);
		}
		&:hover {
			&::before {
				background: linear-gradient(90deg, var(--cbi-red) 0%, #f45a64 100%);
			}
		}
	}
	&.blue {
		background-color: var(--cbi-blue);
		&.disabled {
			background-color: var(--cbi-blue-2);
		}
		&:hover {
			&::before {
				background: linear-gradient(90deg, var(--cbi-blue) 0%, #4590ff 100%);
			}
		}
	}
	&.gray {
		background-color: var(--cbi-gray-01);
		&.disabled {
			background-color: var(--cbi-gray-02);
		}
	}
	&.black {
		background-color: var(--cbi-black);
		color: var(--cbi-top-white);
		&.disabled {
			background-color: var(--cbi-black-2);
		}
		&:hover {
			&::before {
				background: linear-gradient(90deg, var(--cbi-black) 0%, #3d3d4a 100%);
			}
		}
	}
	&.white {
		background-color: var(--cbi-top-white);
		color: var(--cbi-top-black);
		&.disabled {
			background-color: var(--cbi-gray-3);
		}
		&:hover {
			&::before {
				background: linear-gradient(90deg, #fff 0%, #d9e4f4 100%);
			}
		}
	}
	&.line {
		border: 1px solid var(--cbi-gray-5);
		background-color: var(--cbi-top-white);
		&.disabled {
			color: var(--cbi-gray-5);
			background-color: var(--cbi-top-white);
			&:hover {
				&::before {
					background: transparent;
				}
			}
		}
		&.blue {
			border: 1px solid var(--cbi-blue);
			color: var(--cbi-blue);
			&:hover {
				&::before {
					background: linear-gradient(90deg, #fff 0%, #d9e4f4 100%);
				}
			}
		}
	}

	&.large {
		min-width: 148px;
		font-size: 18px;
		padding: 16px 25px;
		border-radius: 8px;
	}
	&.medium {
		font-size: 16px;
		padding: 15px 22px;
		border-radius: 6px;
	}
	&.small {
		font-size: 14px;
		padding: 11px 20px;
		border-radius: 4px;
	}
	&.circle {
		border-radius: 400px;
	}
	+ .button {
		margin-left: 20px;
	}
	.container {
		display: flex;
		align-items: center;
		.arrow {
			display: none;
			transition: all ease-in-out 0.5s;
		}
		&.moving {
			.arrow {
				display: inline-block;
				margin-left: 10px;
			}
		}
		&.suspend {
			position: relative;
			.arrow {
				position: absolute;
				top: 50%;
				right: 0;
				display: inline-block;
				margin: 0;
				transform: translate((100% + 10px), -50%);
				opacity: 0;
			}
		}
	}
}
@keyframes rotating {
	0% {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
</style>
