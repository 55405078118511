<template>
	<div class="footer">
		<div class="footer-content layout">
			<div class="protocol">
				<div
					class="underline hover-bold"
					@click="handleProtocol('ServiceProtocol')">
					服务协议
				</div>
				<span class="line">|</span>
				<div
					class="underline hover-bold"
					@click="handleProtocol('PrivacyProtocol')">
					隐私政策
				</div>
			</div>
			<div class="copyright">
				Copyright © 2024 CBiMart. All Rights Reserved
			</div>
			<div class="copyright">公司：北京欧美澳软件技术有限公司</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {
		handleProtocol(name) {
			this.$router.push({
				name
			})
		}
	}
}
</script>

<style scoped lang="less">
.footer {
	min-width: 1140px;
	height: 106px;
	font-size: 16px;
	color: #fff;
	background-color: var(--cbi-black);
	.footer-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.line {
		padding: 0 32px;
	}
	.protocol {
		display: flex;
		align-items: center;
		div {
			cursor: pointer;
			&:hover {
				color: var(--cbi-black-5);
			}
		}
	}
}

@media screen and (max-width: @mobileWidth) {
	.footer {
		padding: 30px 0;
		height: 100%;
		font-size: 13px;
		.footer-content {
			display: block;
		}
		.line {
			padding: 0 30px;
		}
		.protocol {
			display: flex;
			align-items: center;
			div {
				cursor: pointer;
				&:hover {
					color: var(--cbi-black-5);
				}
			}
		}
		.copyright {
			padding-top: 24px;
		}
	}
}
</style>
