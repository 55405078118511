var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button",class:[
		{ loading: _vm.loading },
		{ disabled: _vm.loading || _vm.disabled },
		{ circle: _vm.circle },
		_vm.type,
		_vm.size,
		_vm.line ? 'line' : ''
	],on:{"click":_vm.handleClick}},[(_vm.loading)?_c('div',{staticClass:"is-loading"}):_vm._e(),_c('span',{staticClass:"container",class:[
			_vm.arrowConfig.moving ? 'moving' : '',
			_vm.arrowConfig.suspend ? 'suspend' : ''
		]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }