<template>
	<div :class="['header', { sticky }, { opaque }]">
		<div class="header-top layout">
			<img
				class="header-top-logo"
				src="@/assets/image/logo.png"
				alt="LOGO"
				@click="handleClick" />
			<!--<span-->
			<!--	class="header-top-btn"-->
			<!--	@click="handleClick">-->
			<!--	首页-->
			<!--</span>-->
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			sticky: false
		}
	},
	computed: {
		opaque() {
			return ['PrivacyProtocol', 'ServiceProtocol'].includes(this.$route.name)
		}
	},
	mounted() {
		window.addEventListener('scroll', e => {
			e.preventDefault()
			this.watchScrollTop()
		})
		this.watchScrollTop()
	},
	methods: {
		handleClick() {
			this.$router.push({
				name: 'Home'
			})
		},
		watchScrollTop() {
			const scrollTop =
				document.documentElement.scrollTop ||
				document.body.scrollTop ||
				window.pageYOffset
			if (scrollTop <= 30) {
				this.sticky = false
			} else {
				this.sticky = true
			}
		}
	}
}
</script>

<style scoped lang="less">
.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	// 设置成100vw 而非100% 防止fixed布局覆盖scrollbar 产生页面抖动
	width: 100vw;
	font-size: 16px;
	color: #fff;
	line-height: 16px;
	transition: background-color 0.2s ease-in-out;
	background-color: transparent;
	&.sticky {
		background-color: var(--cbi-top-white);
		color: var(--cbi-black);
		box-shadow: 0px 40px 40px 0px rgba(16, 40, 90, 0.06);
	}
	&.opaque {
		background-color: var(--cbi-top-white);
	}
	.header-top {
		display: flex;
		align-items: center;
		padding: 24px 6px;
		.header-top-logo {
			display: block;
			width: 104px;
			height: 25px;
			cursor: pointer;
		}
		.header-top-btn {
			margin-left: 54px;
			color: #171725;
			line-height: 16px;
			cursor: pointer;
		}
	}
}

@media screen and (max-width: @mobileWidth) {
	.header {
		font-size: 14px;
		line-height: 14px;
		.header-top {
			padding: 22px 20px;
			.header-top-btn {
				margin-left: 21px;
			}
		}
	}
}
</style>
