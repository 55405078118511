import Vue from 'vue'
import VueI18n from 'vue-i18n'

import eleZh from 'element-ui/lib/locale/lang/zh-CN'
import eleEn from 'element-ui/lib/locale/lang/en'

import enLocale from './en.json'
import zhLocale from './zh-CN.json'

import store from '@/store'
import { upperFirst } from 'lodash-es'

const enCache = {}
const cnCache = {}
Vue.use(VueI18n)

const messages = {
	en: {
		...enLocale,
		...eleEn,
		...Object.values(enCache).reduce((acc, module) => {
			return { ...acc, ...module }
		}, {})
	},
	'zh-CN': {
		...zhLocale,
		...eleZh,
		...Object.values(cnCache).reduce((acc, module) => {
			return { ...acc, ...module }
		}, {})
	}
}

class CustomFormatter {
	interpolate(message) {
		const result = upperFirst(message)
		return result
	}
}

const i18n = new VueI18n({
	locale: (store && store.state.app && store.state.app.lang) || 'zh-CN',
	messages,
	silentFallbackWarn: true,
	silentTranslationWarn: true,
	formatter: new CustomFormatter()
})

export { i18n as default }
