export default {
	namespaced: true,
	state: {
		activeTab: 'Home',
		tabData: [
			{ label: '产品服务', value: 'Home' },
			{ label: '安全合规', value: 'Compliance' },
			{ label: '新闻资讯', value: 'News' },
			{ label: '富港智库', value: 'Wiki' }
		]
	},
	mutations: {
		SET_ACTIVE_TAB(state, tab) {
			state.activeTab = tab
		}
	},
	actions: {}
}
