<template>
	<svg
		:width="size"
		:height="size"
		class="icon svg-wrapper"
		aria-hidden="true"
		v-on="$listeners">
		<use :xlink:href="iconName"></use>
	</svg>
</template>

<script>
export default {
	name: 'AliIcon',
	props: {
		name: {
			type: String,
			default: ''
		},
		size: {
			type: [String, Number],
			default: 20
		}
	},
	computed: {
		iconName() {
			return '#' + this.name
		}
	}
}
</script>

<style lang="less" scoped>
.svg-wrapper {
	display: inherit;
	cursor: pointer;
}
</style>
